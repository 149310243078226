/** @jsx jsx */
import React from 'react'
import { jsx, Heading, Text, Box } from 'theme-ui'

interface Props {
  title: string
  subtitle?: string
  extra?: React.ReactNode
  spaceBetween?: boolean
  padding?: boolean
}

const PageHeader = ({ title, subtitle, extra, spaceBetween, padding }: Props) => {
  return (
    <Box mb={4}>
      <div
        sx={{
          display: 'flex',
          width: '100%',
          justifyContent: spaceBetween ? 'space-between': 'initial',
          '@media only screen and (max-width: 1024px)': {
            width: '100%',
            borderLeft: 'none',
            flexDirection: 'column'
          }
        }}
      >
        <Heading
          sx={{
            padding: padding ? '2.25rem 0 0 2.25rem' : 'initial',
            '@media only screen and (max-width: 1024px)' : { padding: padding ? '1.25rem 0 1.25rem 1.25rem' : 'initial' },
          }}
          as="h1">
            {title}
          </Heading>
        {!!subtitle && <Text>{subtitle}</Text>}
        {!!extra && (
          <div
            sx={{
              display: 'flex',
              alignItems: 'center',
              flexFlow: 'row wrap',
              width: '373px',
              borderLeft: '1px solid #000',
              padding: '2rem 0 2rem 2rem',
              marginBottom: '-32px',
              '@media only screen and (max-width: 1024px)': {
                borderLeft: 'none',
                padding: '1.25rem 0 1.25rem 1.25rem',
                borderTop: '1px solid #000',
                width: '100%'
              }
            }}
          >
            {extra}
          </div>
        )}
      </div>
    </Box>
  )
}

export default PageHeader
