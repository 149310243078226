import React from 'react'
import { Link } from 'gatsby'

import PageHeader from '@components/PageHeader'

const PostTitle = ({ title, path }) => {
  if (path) {
    return (
      <Link to={path}>
        <h2 className="title">{title}</h2>
      </Link>
    )
  }
  return <h2 className="title">{title}</h2>
}

const articlePreview = (post, index) => {
  const img = post.img.replace('/img/', '')
  return (
    <div key={index} className="partner">
      <Link className="img-container" to={post.path}>
        <div
          className="img"
          title={post.title}
          style={{ backgroundImage: `url(${post.img || img})` }}
        ></div>
      </Link>
      <div className="content">
        {PostTitle(post)}
        <p className="description no-flicker">{post.description}</p>
        <Link to={post.path}>
          <span>{post.date}</span>
        </Link>
      </div>
    </div>
  )
}

const PostsOverview = ({ data: { title, subtitle, posts } }) => {
  return (
    <div className="partnerships">
      <PageHeader title={title} subtitle={subtitle} />

      <div className="partners">
        {posts.map((partner, index) => articlePreview(partner, index))}
      </div>
    </div>
  )
}

export default PostsOverview
