import React from 'react'
import { Link } from 'gatsby'

import Layout from '@components/Layout'
import SEO from '@components/SEO'
import PostsOverview from '@components/PostsOverview'
import { useStore } from '@stores/3d'

const partners = [
  {
    title: 'Adidas',
    img: 'img/adidas.jpg',
    description:
      'Adidas and The New Originals gathered five European rappers for an exclusive invite-only live event on a Wednesday night to shoot parts of their new campaign. Artists like from The Netherlands, Germany, Italy and the UK performed for a happy audience of 150 in our dark club with our full blown soundsystem. During the event drinks & pizzas were catered.',
  },
  {
    title: 'Kornuit',
    img: 'img/kornuit.jpg',
    description:
      'Our beer partner Kornuit/Grolsch powers 10 events per year where they help us book a bigger name and still keep the entrance free. The set of the night will be streamed live on Facebook and on our website. Before the event we interview the DJ about online radio culture (written, in Dutch) and after the event we post a photo serie of the night itself.',
  },
  {
    title: 'Porsche',
    img: 'img/porsche.png',
    description:
      'The iconic Veronicaschip, the legendary first Dutch pirate radio ship, hosted the Scopes by Porsche event where Radio Radio programmed one night and streamed the sets of upcoming DJs Lewski (De School) and Eversines (De Lichting). A multi-camera setup was installed to showcase the night and our livestream possibilities range from streaming directly on websites to Facebook pages to Instagram accounts.',
  },
  {
    title: 'Boiler Room',
    img: 'img/boilerroom.jpg',
    description:
      'At Radio Radio we also have the opportunity to combine our venue with the next door Westergastheater (500 - 1000 cap). The first time we did this was during ADE 2018 for the majestic Boiler Room in collaboration with the queer party Is Burning.',
  },
  {
    title: 'Eilân',
    img: 'img/eilan.png',
    description:
      'Chasing the Hihat and MOJO joined forces for what it seemed the absolute best festival of the summer: Eilân. DJs like Motor City Drum Ensemble, Antal and Apparat were supposed to play the beautiful island of Terschelling and Radio Radio planned to do a four day livestream (both video and audio) stage here, featuring over 40 acts from the festival for DJ sets, interviews and listening sessions. Unfortunately the festival never happened due to legal actions and permit issues.',
  },
]

const SubtitleComponent = () => (
  <p>
    <Link to="/contact">Get in touch</Link> to collaborate on streaming, event
    hosting, festivals and Radio Radio rentals. Besides this Radio Radio creates
    and curates music events for brands, sets up live video streams and are able
    to conceptualise and produce music content, both written and video content.
  </p>
)

const data = {
  posts: partners,
  subtitle: <SubtitleComponent />,
  title: 'Partnerships',
}

const PartnershipsPage = () => {
  const backgroundColor = useStore((s) => s.color)

  return (
    <Layout>
      <SEO title="Partnerships" />
      <div style={{ backgroundColor: backgroundColor }}>
        <PostsOverview data={data} />
      </div>
    </Layout>
  )
}

export default PartnershipsPage
